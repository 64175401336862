<template>
  <v-card
    v-if="!$vuetify.breakpoint.smAndDown"
    class="mb-12"
    elevation="0"
  >
    <v-card-text class="pb-12">
      <v-row justify="center">
        <v-col cols="5">
          <v-row>
            <v-col @click="editar('services.contact')" cols="12" class="titulo">
              {{ $t('services.contact') }}
            </v-col>
            <v-col  @click="editar('services.c_subtitle')" cols="12" class="parrafoContact">
              {{ $t('services.c_subtitle') }}
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn
                dark
                raised
                color="#E30707"
                @click="localePathEx('contact-us')"
                >{{ $t('services.c_btn') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5">
          <v-img
            :aspect-ratio="16 / 10"
            :src="img"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-row v-else justify="center" class="mb-12">
    <v-col cols="11">
      <v-img
        :aspect-ratio="$vuetify.breakpoint.smAndDown ? 11 / 10 : 16 / 10"
        :src="img"
      />
    </v-col>
    <v-col @click="editar('services.contact')" cols="11" class="titulo text-center">
      {{ $t('services.contact') }}
      <v-btn
        class="ml-2"
        small
        color="#E30707"
        @click="localePathEx('contact-us')"
      >
        <v-icon color="white">mdi-arrow-right</v-icon></v-btn
      >
    </v-col>
    <v-col @click="editar('services.c_subtitle')" cols="11" class="parrafoContact">
      {{ $t('services.c_subtitle') }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    img: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    localePathEx(name) {
      this.$router.push({name});
    },
  },
}
</script>
