<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card elevation="1" class="formatCards" height="474px">
      <v-img
        height="32.91%"
        :src="image"
        lazy-src="@/assets/img/Loading.gif"
        position="bottom"
      ></v-img>
      <v-card-text>
        <v-row style="margin-top: 20px;">
          <v-col
            class="subTituloSimple"
            cols="12"
            @click="editar('services.so_crads', true, numElement, 'titulo')"
          > {{title}} </v-col>
          <v-col
            style="margin-top: 20px;"
            class="textoSimple"
            cols="12"
            @click="editar('services.so_crads', true, numElement, 'detalle')"
          > {{text}} </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  props: {
    title: {
        type: String,
        default: ''
    },
    text: {
        type: String,
        default: ''
    },
    image: {
        type: String,
        required: true
    },
    numElement: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {};
  },
};
</script>
