<template>
  <v-container fluid class="pa-0 mb-6">
    <v-row justify="center">
      <v-col cols="12">
        <v-img height="634px" class="align-center" :src="get_image.img_0">
          <v-row>
            <v-col
              lg="8"
              cols="12"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center' : ''"
            >
              <div
                @click="editar('services.title')"
                v-if="!$vuetify.breakpoint.smAndDown"
                v-html="$t('services.title')"
                class="textoImagenPc"
              ></div>
              <div
                v-else
                @click="editar('services.title')"
                v-html="$t('services.title')"
                class="textoImagenMobile"
              ></div>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        class="text-center tituloSecundario"
        :style="
          'margin-top:' +
          ($vuetify.breakpoint.smAndDown ? '79px' : '125px') +
          ';'
        "
        @click="editar('services.section_one')"
      >
        {{ $t('services.section_one') }}
      </v-col>
      <v-col
        @click="editar('services.so_subtitle')"
        lg="9"
        md="10"
        sm="12"
        cols="12"
        class="subTitulo"
      >
        {{ $t('services.so_subtitle') }}
      </v-col>
      <v-col
        @click="editar('services.so_details')"
        lg="9"
        md="10"
        sm="12"
        cols="12"
        class="parrafo"
      >
        {{ $t('services.so_details') }}
      </v-col>
      <v-col lg="10" md="11" sm="11" cols="11" style="margin-top: 90px">
        <v-row justify="center">
          <v-col
            v-for="(card, index) in $t('services.so_crads')"
            :key="index"
            lg="6"
            md="8"
            sm="12"
            cols="12"
            style="margin-bottom: 23px"
          >
            <card-services
              :numElement="index"
              :id="card.id"
              :titulo="card.titulo"
              :img="card.img"
              :detalle="card.detalle"
              :textButton="card.view"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="text-center tituloSecundario"
        style="margin-top: 6px"
        @click="editar('services.industry')"
      >
        {{ $t('services.industry') }}
      </v-col>
      <v-col
        lg="8"
        md="10"
        sm="12"
        cols="12"
        class="subTitulo text-center"
        :style="
          $vuetify.breakpoint.smAndDown
            ? 'padding: 0 10% 0 10%;'
            : 'padding: 0 10% 0 10%;'
        "
      >
        <div
          @click="editar('services.i_subtitle')"
          v-html="$t('services.i_subtitle')"
        ></div>
      </v-col>
      <v-col lg="9" md="10" sm="11" cols="11" style="margin-top: 90px">
        <v-row justify="center">
          <v-col v-for="(card, index) in $t('services.i_cards')"
            :key="index" lg="4"
            md="4"
            sm="12"
            cols="12"
            style="margin-bottom: 23px">
            <card-services-two
              :numElement="index"
              :id="card.id"
              :image='card.img'
              :title="card.titulo"
              :text="card.detalle"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-row justify="center" style="background-color: #F4F4F4; margin-top: 30px;">
      <v-col
        cols="12"
        class="text-center tituloSecundario"
        style="margin-top: 6px"
        @click="editar('services.tech')"
      >
        {{ $t('services.tech') }}
      </v-col>
      <v-col
        lg="7"
        md="10"
        sm="12"
        cols="12"
        class="parrafo"
        @click="editar('services.t_subtitle')"
      >
        {{ $t('services.t_subtitle') }}
      </v-col>
      <v-col
        lg="8"
        md="8"
        sm="11"
        cols="11"
        v-if="!$vuetify.breakpoint.smAndDown"
      >
        <v-row justify="center" class="my-9">
          <v-col lg="2" md="2" sm="12" cols="12">
            <div class="text-center mb-3">
              <img src="@/assets/Iconos/GraphicStar.svg" />
            </div>
            <div @click="editar('services.t_one')">
              {{ $t('services.t_one') }}
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col lg="2" md="2" sm="12" cols="12">
            <div class="text-center mb-3">
              <img src="@/assets/Iconos/NoteText.svg" />
            </div>
            <div @click="editar('services.t_two')">
              {{ $t('services.t_two') }}
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col lg="2" md="2" sm="12" cols="12">
            <div class="text-center mb-3">
              <img src="@/assets/Iconos/Clock.svg" />
            </div>
            <div @click="editar('services.t_three')">
              {{ $t('services.t_three') }}
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col lg="3" md="3" sm="12" cols="12">
            <div class="text-center mb-3">
              <img src="@/assets/Iconos/Locate.svg" />
            </div>
            <div @click="editar('services.t_four')">
              {{ $t('services.t_four') }}
            </div>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col lg="2" md="2" sm="12" cols="12">
            <div class="text-center mb-3">
              <img src="@/assets/Iconos/Box.svg" />
            </div>
            <div @click="editar('services.t_five')">
              {{ $t('services.t_five') }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
        style="margin-top: 82px"
        cols="12"
      >
        <v-row justify="center">
          <v-col class="marco" cols="10">
            <v-row align="center">
              <v-col cols="3" class="text-center mt-2">
                <img src="@/assets/Iconos/GraphicStar.svg" />
              </v-col>
              <v-col
                @click="editar('services.t_one')"
                cols="9"
                class="parrafoTechnology"
              >
                {{ $t('services.t_one') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col class="marco" cols="10">
            <v-row align="center">
              <v-col cols="3" class="text-center mt-2">
                <img src="@/assets/Iconos/NoteText.svg" />
              </v-col>
              <v-col
                @click="editar('services.t_two')"
                cols="9"
                class="parrafoTechnology"
              >
                {{ $t('services.t_two') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col class="marco" cols="10">
            <v-row align="center">
              <v-col cols="3" class="text-center mt-2">
                <img src="@/assets/Iconos/Clock.svg" />
              </v-col>
              <v-col
                @click="editar('services.t_three')"
                cols="9"
                class="parrafoTechnology"
              >
                {{ $t('services.t_three') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col class="marco" cols="10">
            <v-row align="center">
              <v-col cols="3" class="text-center mt-2">
                <img src="@/assets/Iconos/Locate.svg" />
              </v-col>
              <v-col
                @click="editar('services.t_four')"
                cols="9"
                class="parrafoTechnology"
              >
                {{ $t('services.t_four') }}
              </v-col>
            </v-row>
          </v-col>
          <v-col class="marco" cols="10">
            <v-row align="center">
              <v-col cols="3" class="text-center mt-2">
                <img src="@/assets/Iconos/Box.svg" />
              </v-col>
              <v-col
                @click="editar('services.t_five')"
                cols="9"
                class="parrafoTechnology"
              >
                {{ $t('services.t_five') }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      </v-row>
      <v-col class="mt-12" lg="10" md="10" sm="11" cols="11">
        <card-contact-us
          :img="
            $t('services.industry') === 'Industry'
              ? get_image.img_7
              : get_image.img_6
          "
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CardServices from '@/components/services/CardServices.vue';
import CardServicesTwo from '@/components/services/CardServicesTwo.vue';
import CardIndustry from '@/components/services/CardIndustry.vue';
import CardContactUs from '@/components/services/CardContactUs.vue';
import { SERVICES } from '@/assets/img/images';

export default {
  nuxtI18n: {
    paths: {
      en: '/services',
      es: '/servicios',
    },
  },
  metaInfo() {
    return {
      title: this.$t('services.seo.title'),
      meta: [
        { name: 'descripción', content: this.$t('services.seo.description') },
        { name: 'keywords', content: this.$t('services.seo.keywords') },
      ],
    };
  },

  mounted() {
    setTimeout(() => {
      if (this.$route.query.section) {
        window.scrollTo({
          top: document.getElementById(this.$route.query.section).offsetTop,
          behavior: 'smooth',
        });
      }
    }, 1000);
  },
  components: {
    CardServices,
    CardIndustry,
    CardContactUs,
    CardServicesTwo
  },
  data() {
    return {};
  },
  computed: {
    getImg(imagen) {
      return require('../assets/Iconos/' + imagen);
    },
    get_image() {
      return SERVICES;
    },
  },
};
</script>
