<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
    width="1000px"
    v-model="dialog"
    transition="dialog-bottom-transition"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="#E30707"
        small
      >
        <p style="color: white; padding: 0px; margin: 0px;">{{textButton}}</p>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          icon
          @click="dialog = false"
        ><v-icon
            color="red"
            size="40"
          >mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col
            cols="12"
          >
            <div class="subTituloSimple mb-10">{{ title }}</div>
          </v-col>
          <v-col
            cols="12"
            style="background-color: #F4F4F4; padding: 80px 25px 25px 25px;"
          >
            <v-row justify="center">
              <v-col
                v-for="field in $t('services.formulario.auto')"
                :key="field.id"
                :lg="field.Number"
                :md="field.Number"
                cols="12"
              >
                <p class="textFieldTitle">{{`${field.name}${field.required ? ' *':''}`}}</p>
                <v-text-field
                  v-model="result_form[field.id]"
                  outlined
                  background-color="white"
                  :type="field.type"
                  :error-messages="validateError(field.id, field.required, field.Number, field.email)"
                  @input="$v.result_form[field.id].$touch()"
                  @blur="$v.result_form[field.id].$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                lg="5"
                md="5"
                cols="12"
              >
                <p class="textFieldTitle">{{$t('services.formulario.manual.ship_date.name')}}</p>
                <date-field
                  :min="today"
                  :max="result_form.delivery_date"
                  v-model="result_form.Ship_date"
                />
              </v-col>
              <v-col
                lg="5"
                md="5"
                cols="12"
              >
                <p class="textFieldTitle">{{$t('services.formulario.manual.delivery_date.name')}}</p>
                <date-field
                  :min="result_form.Ship_date"
                  v-model="result_form.delivery_date"
                />
              </v-col>
              <!-- <v-col
                lg="10"
                md="10"
                cols="12"
              >
                <vue-recaptcha
                  @verify="setJoinUsVerified"
                  size="100"
                  ref="recaptcha"
                  :sitekey="robot"
                />
              </v-col> -->
              <vue-recaptcha
                  @verify="setJoinUsVerified"
                  @expired="onCaptchaExpired"
                  size="100"
                  ref="recaptcha"
                  :sitekey="robot"
                />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col
          cols="10"
          class="px-12"
        >
          <v-btn
            width="150px"
            color="error"
            x-large
            @click="sendEmail"
            :disabled="isJoinUsLock"
            :loading="loading"
          >Submit</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateField from "@/components/base/DateField.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { validationMixin } from 'vuelidate'
import { required, email, numeric } from 'vuelidate/lib/validators'
import axios from 'axios';
export default {
  components: {
    DateField,
    VueRecaptcha,
  },
  mixins: [validationMixin],
  props: {
    textButton: {
      type: String,
      default: "View",
    },
    title: {
      type: String,
      default: "Request a Quote"
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isJoinUsLock: true,
      today: "",
      result_form: {
        Name: "",
        Company: "",
        Email: "",
        Number: "",
        Origin_zip: "",
        Destination_zip: "",
        Pallete_clount: "",
        Shipment_weight: "",
        Ship_date: "",
        Delivery_date: "",
      },
    };
  },
  validations: {
    result_form: {
        Name: {
          required
        },
        Company: {
        },
        Email: {
          email
        },
        Number: {
          numeric
        },
        Origin_zip: {
          numeric
        },
        Destination_zip: {
          numeric
        },
        Pallete_clount: {
        },
        Shipment_weight: {
        },
        Ship_date: {
        },
        Delivery_date: {
        },
      },
  },
  methods: {
    async sendEmailToContact(form) {
      return await axios
        .post(`${process.env.VUE_APP_API}/email-contact-us`, form)
        .finally(() => (this.loading = this.dialog = false));
    },
    sendEmail() {
      this.$v.result_form.$touch()
      if (!this.$v.result_form.$invalid) {
        this.loading = true
        let request = this.result_form;
        request['subject'] = this.title;
        this.sendEmailToContact(request).finally(
          () => (this.loading = false)
        )
      }
    },
    current_date() {
      let date = new Date();
      let day = date.getDate();
      let complete_day = day < 10 ? `0${day}` : day;
      let month = date.getMonth() + 1;
      let complete_month = month < 10 ? `0${month}` : month;
      let String_date = `${date.getFullYear()}-${complete_month}-${complete_day}`;
      this.result_form.Ship_date = String_date;
      this.today = String_date;
    },
    setJoinUsVerified(dato) {
      this.isJoinUsLock = false;
    },
    onCaptchaExpired() {
      this.isJoinUsLock = true;
    },
    validateError(element, required, Number, email){
      const errors = []
      if (!this.$v.result_form[element].$dirty) return errors
      
      if(required) {
        if(!this.$v.result_form[element].required) {
          errors.push('this field is required.')
        }
      }
      if(Number) {
        if(!this.$v.result_form[element].numeric) {
          errors.push('this field wil be a numeric value')
        }
      }
      if(email) {
        if(!this.$v.result_form[element].email) {
          errors.push('this field wil be a email address')
        }
      }
      return errors
    }
  },
  computed: {
    robot() {
      return process.env.VUE_APP_ROBOT;
    },
  },
  created() {
    this.current_date();
  },
};
</script>
<style>
.textFieldTitle {
  color: #4D4D4D;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>