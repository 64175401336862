<template>
<v-container fluid class="pa-0">
  <v-card
    :height="$vuetify.breakpoint.smAndDown ? '':'650px'"
    elevation="0"
    class="formatCards"
    color="#F2F4F6"
  >
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="px-8"
        >
          <v-img
            :height="$vuetify.breakpoint.smAndDown ? '196px' : '155px'"
            :aspect-ratio="13/5"
            :src="img"
            lazy-src="@/assets/img/Loading.gif"
          ></v-img>
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          lg="11"
          md="11"
          cols="12"
          class="subTituloSimple"
          style="height: 90px;"
          @click="editar('services.so_crads', true, numElement, 'titulo')"
        >
          {{titulo}}
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.smAndDown"
          lg="10"
          md="10"
          sm="11"
          cols="11"
          class="textoSimple"
          @click="editar('services.so_crads', true, numElement, 'detalle')"
        >
          {{detalle}}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="!$vuetify.breakpoint.smAndDown"  style="position: absolute; bottom: 0; right: 0;">
        <v-row width="100%" class="justify-content: flex-end;">
          <v-col cols="12" class="my-3 mr-2">
            <formulario :textButton="textButton" :title="textButton"/>
            <!-- <v-btn @click="redirect(id)" color="#E30707" small><p style="color: white; padding: 0px; margin: 0px;">{{textButton}}</p></v-btn> -->
          </v-col>
        </v-row>
      </v-card-actions>
  </v-card>
  <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" style="margin-top: 20px;">
    <v-col
          cols="12"
          class="subTituloSimple text-center mb-6"
          @click="editar('services.so_crads', true, numElement, 'titulo')"
        >
          {{titulo}}
        </v-col>
        <v-col
          lg="10"
          md="10"
          sm="11"
          cols="11"
          class="textoSimple mb-5"
          @click="editar('services.so_crads', true, numElement, 'detalle')"
        >
          {{detalle}}
        </v-col>
        <v-col cols="12" class="text-center">
          <formulario :textButton="textButton" :title="textButton"/>
        </v-col>
  </v-row>
  </v-container>
</template>
<script>
import Formulario from '@/components/services/CardRequest.vue';

export default {
  props: {
    titulo: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    detalle: {
      type: String,
      required: true,
    },
    numElement: {
      type: Number,
      required: true,
    },
    textButton: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: 'home'
    }
  },
  components: {
    Formulario
  },
  data() {
    return {

    }
  },
  methods: {
    redirect(name) {
      this.$router.push({name});
    }
  }
}
</script>
