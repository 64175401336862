<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="fecha"
        readonly
        :label="simple_name ? name : ''"
        outlined
        background-color="white"
        persistent-hint
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="fecha"
      :min="min"
      :max="max"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            default: 'Date'
        },
        simple_name: {
            type: Boolean,
            default: false
        },
        min: {
            type: String,
            default: ''
        },
        max: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            menu: false,
            fecha: '',
        }
    },
    watch: {
        fecha(value) {
            this.$emit("input", value);
        }
    }
}
</script>